import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "shared-ui/ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { height, shouldForwardProp } from "@mui/system";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    background: theme.palette.background.default,
    paddingRight: 16,
    "& input": {
      background: theme.palette.background.default,
      paddingLeft: "4px !important",
      height: '1.3em',
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background:
        theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    background: theme.palette.background.default,
    color: theme.palette.grey[500],
    // border: "1px solid",
    fontSize:'2em',
    "&:hover": {
    //   border: "1px solid",

      background: theme.palette.background.default,
      color: theme.palette.text.menu,
      "& svg": {
        stroke: theme.palette.secondary.main,
      },
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="16px"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <HeaderAvatarStyle variant="rounded">
            <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
          </HeaderAvatarStyle>
          <Box sx={{ ml: 2 }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.main
                    : theme.palette.orange.light,
                color: theme.palette.orange.dark,
                "&:hover": {
                  background: theme.palette.orange.dark,
                  color: theme.palette.orange.light,
                },
              }}
              {...bindToggle(popupState)}
            >
              <IconX stroke={1.5} size="20px" />
            </Avatar>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const theme = useTheme();
  const [value, setValue] = useState("");

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none"},
       width: '100%',
       height:'100%',
       alignItems: 'center',  
       textAlign:'center',
       width: 48,
       height: 48,
       borderRadius:3,
       transition: 'all .2s ease-in-out',
       background: theme.palette.background.default,
       color: theme.palette.grey[500],
       mr:1,
       
       border:'1px solid !important',
       borderColor: theme.palette.background.border,

       '&[aria-controls="menu-list-grow"],&:hover': {
           border:'1px solid !important',
           borderColor: theme.palette.secondary.main,
           color: theme.palette.text.menu,
           '& svg': {
               stroke:theme.palette.secondary.main,
           }
       }
    }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box p={.7}>
                <HeaderAvatarStyle
                  variant="rounded"
                  {...bindToggle(popupState)}
                >
                  <IconSearch stroke={1.5} size="28px" />
                </HeaderAvatarStyle>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background:
                            theme.palette.mode === "dark"
                              ? theme.palette.dark[900]
                              : "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <MobileSearch
                                value={value}
                                setValue={setValue}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <OutlineInputStyle
          id="input-search-header"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <IconSearch
                stroke={1.5}
                size="28px"
                color={theme.palette.grey[500]}
              />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end" sx={{
                background: theme.palette.background.default,
            }}>
              <HeaderAvatarStyle
                variant="rounded"
              >
                <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
              </HeaderAvatarStyle>
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />
      </Box>
    </>
  );
};

export default SearchSection;
