import axios from "axios";
// import { useNavigate } from "react-router-dom";

const baseURL = `${
  process.env.REACT_APP_MONGO_API_URL || "http://localhost:7015"
}`;
// const baseURL = `http://localhost:7015`;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// // Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("serviceToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // config.headers['Accept-Encoding'] = 'gzip, deflate';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration (401)
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // const navigate = useNavigate();
        if (error.response && error.response.status === 401 || error.response.status === 403) {
            // Token has expired or is invalid
            console.log('Token has expired or is invalid. Redirecting to login...');
            localStorage.removeItem('serviceToken'); // Remove expired token
            window.location.href = '/login'; // Redirect to login page
            // navigate("/login");
        }
        return Promise.reject(error);
    }
);

// / Add a response interceptor to handle token expiration (401)

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const navigate = useNavigate();
//     if (error.response && error.response.status === 401 || error.response.status === 403) {
//       // Token has expired or is invalid
//       console.log("Token has expired or is invalid. Redirecting to login...");
//       localStorage.removeItem("serviceToken"); // Remove expired token

//       navigate("/login"); // Use React Router's navigate function
//     }
//     return Promise.reject(error);
//   }
// );

const logError = (error) => {
  if (error.response) {
    console.log("Server Response Error:", error.response.data);
    console.log("Response Status:", error.response.status);
    console.log("Response Headers:", error.response.headers);
  } else if (error.request) {
    console.log("Request Error:", error.request);
  } else {
    console.log("General Error:", error.message);
  }
};

const mongoService = {
  // Create document
  async createDocument(collection, document) {
    const url = `${baseURL}/${collection}`;
    try {
      const response = await axiosInstance.post(url, document);
      return response.data;
    } catch (error) {
      logError(error);
      throw error;
    }
  },

  // Get all documents with pagination and sorting
  async getDocuments(
    collection,
    page = 1,
    limit = 10,
    sortField = "_id",
    sortOrder = "asc",
    filters = {}
  ) {
    let queryParams = `page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}`;
    for (const [key, value] of Object.entries(filters)) {
      queryParams += `&${key}=${value}`;
    }
    const url = `${baseURL}/${collection}?${queryParams}`;
    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      logError(error);
      throw error;
    }
  },

  // Get single document by ID
  async getDocument(collection, id) {
    const url = `${baseURL}/${collection}/${id}`;
    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      logError(error);
      throw error;
    }
  },

  // Update document by ID
  async updateDocument(collection, id, updates) {
    const url = `${baseURL}/${collection}/${id}`;
    try {
      const response = await axiosInstance.put(url, updates);
      return response.data;
    } catch (error) {
      logError(error);
      throw error;
    }
  },

  // Delete document by ID
  async deleteDocument(collection, id) {
    const url = `${baseURL}/${collection}/${id}`;
    try {
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      logError(error);
      throw error;
    }
  },
};

export default mongoService;
