// ButtonMenu.js

import PropTypes from "prop-types";
import { ButtonBase, ListItemIcon, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { uiStore } from 'shared-ui/store';


const ButtonMenu = ({ icon: Icon, name, onClick, selected }) => {
    const theme = useTheme();
    const { drawerOpen, layout } = uiStore.config;

    return (
        <ButtonBase
            onClick={onClick}
            sx={{
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: drawerOpen ? 'start' : 'center',
                padding: "1rem ",
                border:'1px solid',
                borderColor: selected ? theme.palette.background.borderMenu : 'transparent',
                mx: 'auto',
                my:2,
                width: drawerOpen ? '100%' : '48px',
                height: drawerOpen ? '' : '48px',
                transition:'.2s',

                backgroundColor: selected ? theme.palette.secondary.light : "transparent",
                "&:hover": {
                    backgroundColor: theme.palette.secondary.light,
                },
                "&.Mui-selected": {
                    background: theme.palette.background.menu,
                    color: theme.palette.text.menu,
                    border: "1px solid",
                    borderColor: theme.palette.background.borderMenu,
                  },

            }}
        >
            <ListItemIcon
                sx={{
                    width: 35,
                    color: selected ? theme.palette.secondary.main : theme.palette.text.primary,
                    display: drawerOpen  ? '' : 'flex',
                    justifyContent: drawerOpen? '' : 'center'
                }}
            >
                {Icon && <Icon  size={drawerOpen ? "20px" : "24px"} />}
            </ListItemIcon>
            <Typography
                variant="body1"
                sx={{
                fontSize:'1.19em',
                    display: drawerOpen? 'block' : 'none',
                    color: selected ? theme.palette.secondary.main : theme.palette.text.primary,
                    marginLeft: 1,
                }}
            >
                {name}
            </Typography>
        </ButtonBase>
    );
};

ButtonMenu.propTypes = {
    icon: PropTypes.elementType, // Expecting an icon component
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
};



export default ButtonMenu;
