import { lazy } from 'react';
 
// project imports
import AuthGuard from 'shared-ui/utils/route-guard/AuthGuard';
import MainLayout from 'shared-ui/layout/MainLayout';
import Loadable from 'shared-ui/ui-component/Loadable';
 
const Apps = Loadable(lazy(() => import('views/apps')));
const ViewMorePage = Loadable(lazy(() => import('views/apps/groups')));
const Streamlit = Loadable(lazy(() => import('views/streamlit')));
 
// ==============================|| MAIN ROUTING ||============================== //
 
const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/apps',
            element: <Apps/>
        },
        {
            path: '/apps/:appId',
            element: <Streamlit />
        },
        {
            path: '/view-more',
            element: <ViewMorePage />
        },
        {
            path: 'apps/:groupName/:groupId',
            element: <ViewMorePage />
        },  
    ]
};
 
export default MainRoutes;