import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'; // Correct import

// ==============================|| LOGO ||============================== //

import logo from 'shared-ui/assets/images/emstl-logo.png';
import logoDark from 'shared-ui/assets/images/logodark.png';


const Logo = () => {
    const theme = useTheme();

    


    return <img src={theme.palette.mode === 'dark' ? logoDark : logo} 
    alt="Emstl" 
    className='logoSize'// Adjust width based on screen sizewidth="95%" height="auto" 
    />;
};

export default Logo;
