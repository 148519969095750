// third-party
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// assets
import { IconKey, IconBug } from '@tabler/icons';
import { Language, PhotoCameraBack} from '@mui/icons-material';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import cardIcon from "shared-ui/assets/images/cards/iconCard.svg";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import mongoService from 'service/mongoService';

// constant
const icons = { IconBug, IconKey };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //


const application = {
    id: 'applications',
    // title: <FormattedMessage id="applications" />,
    // caption: <FormattedMessage id="use-ui-models" />,
    icon: icons.IconKey,
    type: 'group',
    children: [
        // {
        //     id: 'image',
        //     title: <FormattedMessage id="image" />,
        //     type: 'item',
        //     url: '/application/image-apps',
        //     icon: PhotoCameraBack
        // },
        // {
        //     id: 'language',
        //     title: <FormattedMessage id="language" />,
        //     type: 'item',
        //     url: '/application/llm-apps',
        //     icon: Language
        // },
         {
            id: 'home',
            title: <FormattedMessage id="Applications" />,
            type: 'item',
            url: '/apps',
            icon: DashboardCustomizeOutlinedIcon
        },
        // {
        //     id: 'settings',
        //     title: <FormattedMessage id="settings" />,
        //     type: 'item',
        //     url: '/home',
        //     icon: SettingsOutlinedIcon,
        // },
        // {
        //     id: 'Dataset',
        //     title: <FormattedMessage id="Dataset" />,
        //     type: 'item',
        //     url: '/home',
        //     icon: SettingsOutlinedIcon,
        // }

    ]
};

export default application;
